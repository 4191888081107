/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyBPlv0qRnqjPJYYwc9z43tZo3bpTOOjhkg",
  "appId": "1:644449886346:web:517aad6f1f7ebb97490d73",
  "authDomain": "schooldog-i-elbert-ga.firebaseapp.com",
  "measurementId": "G-7VPNQZGH63",
  "messagingSenderId": "644449886346",
  "project": "schooldog-i-elbert-ga",
  "projectId": "schooldog-i-elbert-ga",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-elbert-ga.appspot.com"
}
